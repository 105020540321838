import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer' 

const getContent = graphql`
query {
  allContentfulVelkoobchod {
     edges {
       node {
         nazev
         hlavniPopis {
           json
         }
       }
     }
   }
}
`

const VelkoContent = () => {
  const { allContentfulVelkoobchod : {edges} } = useStaticQuery(getContent);
  const {nazev, hlavniPopis} = edges[0].node;
  return (
    <div> 
    <h3 className='no-line'>{nazev}</h3>
    {documentToReactComponents(hlavniPopis.json)}
    </div>
  )
}

export default VelkoContent
