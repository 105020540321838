import React from "react"
import Layout from "../components/layout/Layout"
import Cta from "../components/sections/Cta"
import Shop from "../components/shop/Shop"
import SideProjects from "../components/projects/SideProjects"
import Main from "../components/pageLayout/Main"
import Sidebar from "../components/pageLayout/Sidebar"
import Content from "../components/pageLayout/Content"
import Banner from "../components/header/Banner"
import SideShop from "../components/shop/SideShop"
import SideContact from "../components/contact/SideContact"
import Distributors from "../components/sections/Distributors"
import SEO from "../components/SEO"
import VelkoContent from "../components/sections/VelkoContent"
import { graphql, useStaticQuery } from "gatsby"

export default () => {
  const image = useStaticQuery(graphql`
    query {
      img: contentfulAsset(title: { eq: "Plyn-trubky" }) {
        fluid(maxWidth: 1600, quality: 50) {
          ...GatsbyContentfulFluid
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Velkoobchod" />
      <Banner
        text="Labore aliqua proident sunt cupidatat voluptate elit sit ex. Anim excepteur veniam nisi cillum anim sint nisi ullamco. Cupidatat eiusmod eu dolor ad excepteur pariatur ea culpa do aliqua voluptate."
        heading="Velkoobchod"
        img={image.img.fluid}
      />
      <Main>
        <Sidebar>
          <SideContact />
          <SideShop hide={true} />
          <SideProjects />
        </Sidebar>
        <Content>
          <VelkoContent />
        </Content>
      </Main>
      <Cta text="velko" />
      <Shop hideUnderlay secondary/>
      <Distributors />
    </Layout>
  )
}
